import { useStaticQuery, graphql } from 'gatsby'

export default function FAQData() {

  const content = useStaticQuery(graphql`
    query FaqQuery {
      content: contentfulEvergreenContent(readableId: { eq: "layout-faq-v3" }, node_locale: { eq: "en-US" }) {
        name
        pageTitle
        metaDescription
        canonicalLink {
          title
          url
        }
        faqGroups: references {
          ... on ContentfulEvergreenContent {
            id
            readableId
            pageTitle
            faqs: references {
              ... on ContentfulEvergreenContent {
                id
                readableId
                name
                pageTitle
                content {
                  json
                }
              }
            }
          }
        }
      }
    }
  `)

  return content.content
}
