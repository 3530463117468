import React from 'react'
import SEO from '../components/SEO'
import documentToReactComponents from '../utils/richTextUtils'
import Collapsible from '../components/common/Collapsible'
import FAQData from '../components/FAQ/FAQData'

const FAQPage = ({ location }) => {
  const content = FAQData()
  const { faqGroups } = content
  if (!content) {
    return
  }

  const hash = location.hash.split('#')[1]

  const Divider = () => <div className="border-b border-grey-light pt-2 mb-4" />

  const headerClasses =
    'text-lg flex flex`-row w-full my-4 leading-snug font-bold text-black'

  return (
    <div className="w-full max-w-5xl mx-auto px-4">
      <SEO
        title={content.pageTitle}
        description={content.metaDescription}
        canonicalLink={content.canonicalLink}
        location={location}
      />
      <div className="mt-10 md:mt-20 text-3xl">
        <h1 className="heading--1 text-center">{content.pageTitle}</h1>
      </div>
      <div className="w-full inline-block mb-9__gp">
        <div className="w-full px-6 lg:mx-auto">
          {faqGroups.map((faqGroup) => (
            <div key={faqGroup.id} id={faqGroup.readableId}>
              <h2 className="heading--2">{faqGroup.pageTitle}</h2>
              {faqGroup.faqs.map((faq) => (
                <div key={faq.id} id={faq.readableId}>
                  <Collapsible
                    title={faq.pageTitle}
                    styles={{
                      header: headerClasses
                    }}
                    expanded={hash === faq.readableId}
                  >
                    <div className="leading-relaxed mb-6">
                      {documentToReactComponents({
                        richTextDocument: faq.content.json
                      })}
                    </div>
                  </Collapsible>
                  <Divider />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQPage
